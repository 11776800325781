<template>
	<div class="user bgcbdr" v-if="user">
		<img src="@/assets/logoProfile.png" alt="" class="mb1" />
		<h3 class="mb1">{{ user.username }}</h3>
		<h4 class="mb3">{{ user.last_name }} {{ user.first_name }}</h4>
		<!-- <h4 class="mb3">{{ user.email }}</h4> -->
		<div class="profileNav mb2">
			<h5><i class="fas fa-info-circle"></i>{{ $t("dashboard_profile") }}</h5>
			<h5><i class="fas fa-cog"></i>{{ $t("dashboard_settings") }}</h5>
			<h5 @click="logOut"><i class="fas fa-sign-out-alt"></i>{{ $t("dashboard_logout") }}</h5>
		</div>
	</div>
</template>

<script>
import axios from '../../../axios';

export default {
	name: "user-dashboard",
	data() {
		return {
			user: null
		}
	},
	methods: {
		logOut() {
			this.show_menu = false
			this.$store.dispatch("logOut");
		},
		async getLogHist() {
			var self = this
			try {
				await axios.post('/auth/user-info/')
					.then(function (response) {
						self.user = response.data.user
					})
					.catch(function (error) {
						console.error(error);
					});

			} catch (e) {
				alert('Error')
			}
		}
	},
	mounted() {
		this.getLogHist();
	}
};
</script>

<style>
.user {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.user h3 {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 29px;
}

.user h4 {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;

	color: #cccccc;
}

.profileNav {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	justify-content: space-around;
	width: 100%;
}

.profileNav h5 {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 32px;
}

.profileNav h5:hover {
	color: #0e9cff;
	cursor: pointer;
}

@media (min-width: 1200px) and (max-width: 1440px) {}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (max-width: 479px) {}
</style>
