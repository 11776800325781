<template>
  <div class="kyc bgcbdr">
    <h3 class="mb2">{{ $t("card_dashboard_title_h3") }}</h3>
    <div class="child_KYC">
      <h3>{{ $t("card_dashboard_title_h3_2") }}</h3>
      <div class="kyc-list-title">
        <h5>{{ $t("card_dashboard_title_rules") }}</h5>
        <ul class="needed-items-list">
          <li>
            {{ $t("card_dashboard_li_1_1") }} <br>
            {{ $t("card_dashboard_li_1_2") }} <br>
            {{ $t("card_dashboard_li_1_3") }}
          </li>
          
          <li>
            {{ $t("card_dashboard_li_2_1") }} <br>
            {{ $t("card_dashboard_li_2_2") }} <br>
            {{ $t("card_dashboard_li_2_3") }} <br>
            {{ $t("card_dashboard_li_2_4") }}  
          </li>
        </ul>
      </div>
    </div>
    <hr>
    <div class="group_btn_card">
      <button class="kyc_button" :class="{ activeBtn: show_add_physical_card == true }" type="button"
        @click="show_add_physical_card = !show_add_physical_card">{{
          show_add_physical_card ?
          $t('card_dashboard_btn_close') : $t('card_dashboard_btn_add_physical_card') }}</button>
      <button class="kyc_button" :class="{ activeBtn: show_add_virtual_card == true }" type="button"
        @click="show_add_virtual_card = !show_add_virtual_card">{{
          show_add_virtual_card ?
          $t('card_dashboard_btn_close') : $t('card_dashboard_btn_add_virtual_card') }}</button>
      <button class="kyc_button" :class="{ activeBtn: show_list_card == true }" type="button"
        @click="show_list_card = !show_list_card">{{ show_list_card ?
          $t('card_dashboard_btn_close') : $t('card_dashboard_btn_add_list_card') }}</button>
    </div>
    <!-- PHYSICAL CARD     -->
    <div class="border_block_card" v-if="show_add_physical_card === true && kycstate === false">
      <form action="#" method="post" enctype="multipart/form-data">
        <div class="input_card_block">
          <h3 class="title">{{ $t("card_dashboard_physic_number") }} </h3>
          <!-- v-model="$v.number_card_physic.$model" -->
          <input type="text" v-model="$v.number_card_physic.$model" @input="$v.number_card_physic.$touch()">
          <p class="error_form" v-if="!$v.number_card_physic.required">{{ $t("card_dashboard_physic_err_1_1") }}</p>
          <p class="error_form" v-if="!$v.number_card_physic.luhn">{{ $t("card_dashboard_physic_err_1_2") }}</p>
        </div>
        <div class="main_card">
          <div class="upload_files">
            <h3 class="card_title">{{ $t("card_dashboard_physic_title_1") }}</h3>
            <label for="IdentityDocumentsInput3" class="drop-container" id="dropcontainer-3">
              <span class="drop-title">{{ $t("card_dashboard_selected_file") }}</span>
              <input type="file" name="photo1" id="IdentityDocumentsInput3" accept="image/png, image/jpg, image/jpeg"
                v-on:change="handleFilesUpload()" ref="files" required>
            </label>
            <p class="error_form" v-if="!$v.uploaded_physic_card.required">{{ $t("card_dashboard_required") }}</p>
          </div>
          <div class="explain_upload_files">
            <img src="@/assets/cards/phys.jpg" alt="" v-if="uploaded_physic_card == null">
            <img :src="uploaded_physic_card" alt="" v-if="uploaded_physic_card">
          </div>
        </div>

      </form>
      <div class="kyc_btn">
        <div class="check_box">
                      
            
          <input type="checkbox" name="" id="" :value="true" :unchecked-value="false"
            v-model="$v.allow_data_physic.$model" @change="$v.allow_data_physic.$touch()" />
          <h4 @click="allow_data_physic = !allow_data_physic">{{ $t("card_dashboard_agree_1") }} <br> {{ $t("card_dashboard_agree_2") }}.</h4>
        </div>
        <button class="kyc_button submit-button" type="button" @click="send_physic_card">{{ $t("card_dashboard_btn_submit") }}</button>
      </div>
      <p class="error_form" v-if="!$v.allow_data_physic.sameAs">{{ $t("card_dashboard_required") }}</p>
    </div>
    <!-- VIRTUAL CARD  -->
    <div class="border_block_card" v-if="show_add_virtual_card === true && kycstate === false">
      <form action="#" method="post" enctype="multipart/form-data">
        <div class="input_card_block">
          <h3 class="title">{{ $t("card_dashboard_physic_number") }}</h3>
          <input type="text" v-model="$v.number_card_virtual.$model" @input="$v.number_card_virtual.$touch()">
          <p class="error_form" v-if="!$v.number_card_virtual.required">{{ $t("card_dashboard_physic_err_1_1") }}</p>
          <p class="error_form" v-if="!$v.number_card_virtual.luhn">{{ $t("card_dashboard_physic_err_1_2") }}</p>
        </div>
        <div class="main_card">
          <div class="upload_files">
            <h3 class="card_title">{{ $t("card_dashboard_virtual_title_1") }}</h3>
            <label for="IdentityDocumentsInput4" class="drop-container" id="dropcontainer-4">
              <span class="drop-title">{{ $t("card_dashboard_selected_file") }}</span>
              <input type="file" name="photo1" id="IdentityDocumentsInput4" accept="image/png, image/jpg, image/jpeg"
                v-on:change="handleFilesUploadVirtualScreanshot()" ref="filesScreanshot" required>
            </label>
            <p class="error_form" v-if="!$v.uploaded_virtual_card_screanshot.required">{{ $t("card_dashboard_required") }}</p>
          </div>
          <div class="explain_upload_files">
            <img src="@/assets/cards/virt_1.png" alt="" v-if="uploaded_virtual_card_screanshot == null">
            <img :src="uploaded_virtual_card_screanshot" alt="" v-if="uploaded_virtual_card_screanshot">
          </div>
        </div>
        <div class="main_card">
          <div class="upload_files">
            <h3 class="card_title">{{ $t("card_dashboard_virtual_title_2") }}</h3>
            <label for="IdentityDocumentsInput5" class="drop-container" id="dropcontainer-5">
              <span class="drop-title">{{ $t("card_dashboard_selected_file") }}</span>
              <input type="file" name="photo1" id="IdentityDocumentsInput5" accept="image/png, image/jpg, image/jpeg"
                v-on:change="handleFilesUploadVirtualFact()" ref="filesFact" required>
            </label>
            <p class="error_form" v-if="!$v.uploaded_virtual_card_fact.required">{{ $t("card_dashboard_required") }}</p>
            
          </div>
          <div class="explain_upload_files">
            <img src="@/assets/cards/virt_2.png" alt="" v-if="uploaded_virtual_card_fact == null">
            <img :src="uploaded_virtual_card_fact" alt="" v-if="uploaded_virtual_card_fact">
          </div>
        </div>

      </form>
      <div class="kyc_btn">
        <div class="check_box">
          <input type="checkbox" name="" id="" :value="true" :unchecked-value="false"
            v-model="$v.allow_data_virtual.$model" @change="$v.allow_data_virtual.$touch()" />
          <h4 @click="allow_data_virtual = !allow_data_virtual">{{ $t("card_dashboard_agree_1") }} <br> {{ $t("card_dashboard_agree_2") }}.</h4>

        </div>
        <button class="kyc_button submit-button" type="button" @click="send_virtual_card">{{ $t("card_dashboard_btn_submit") }}</button>
      </div>
      <p class="error_form" v-if="!$v.allow_data_virtual.sameAs">{{ $t("card_dashboard_required") }}</p>
    </div>

    <div class="child" v-if="show_list_card === true && kycstate === false">
      <ul class="main_list_card" v-if="card_list.length > 0">
        <li class="title_list">
          <span>{{ $t("card_dashboard_list_titles_1") }}</span>
          <span>{{ $t("card_dashboard_list_titles_2") }}</span>
          <span>{{ $t("card_dashboard_list_titles_3") }}</span>
        </li>
        <li v-for="(item, index) in card_list" :key="index">
          <span>{{item.number_card}}</span>
          <span v-if="item.type_card == 1">{{ $t("card_dashboard_list_type_1") }}</span>
          <span v-if="item.type_card == 2">{{ $t("card_dashboard_list_type_2") }}</span>
          <span v-if="item.status_card == 1" style="color:orange">{{ $t("card_dashboard_list_status_1") }}</span>
          <span v-if="item.status_card == 2" style="color:lawngreen">{{ $t("card_dashboard_list_status_2") }}</span>
          <span v-if="item.status_card == 3" style="color:red">{{ $t("card_dashboard_list_status_3") }}</span>
        </li>
      </ul>
      <ul class="main_list_card" v-else>
        <li class="title_list">
          <span>{{ $t("card_dashboard_list_titles_1_fake") }}</span>
          <span>{{ $t("card_dashboard_list_titles_2_fake") }}</span>
        </li>
        <li>
          <span>{{ $t("card_dashboard_list_status_1_fake") }}</span>
          <span>{{ $t("card_dashboard_list_status_2_fake") }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { validationMixin } from 'vuelidate'
import luhn from 'luhn-alg'
import {
  required,
  sameAs
  // helpers,
  // between
  // maxValue
  // minValue
} from 'vuelidate/lib/validators'
export default {
  name: "card_dashboard",
  data() {
    return {
      kycstate: false,
      show_add_physical_card: false,
      show_add_virtual_card: false,
      show_list_card: false,
      kycprogress: null,
      status_kyc: 0,
      file_test: [],
      // Physical CARD
      uploaded_physic_card: null,
      error_uploaded_physic_card: "",
      number_card_physic: '',
      error_number_card_physic: "",
      allow_data_physic: false,
      error_allow_data_physic: "",
      // Virtual CARD
      uploaded_virtual_card_screanshot: null,
      error_uploaded_virtual_card_screanshot: "",
      uploaded_virtual_card_fact: null,
      error_uploaded_virtual_card_fact: "",
      number_card_virtual: '',
      error_number_card_virtual: "",
      allow_data_virtual: false,
      error_allow_data_virtual: "",
      // Список карт, номер тип и состояние
      card_list: []
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      number_card_physic: {
        required,
        luhn
      },
      uploaded_physic_card:{
        required
      },
      uploaded_virtual_card_screanshot:{
        required
      },
      uploaded_virtual_card_fact:{
        required
      },
      number_card_virtual: {
        required,
        luhn
      },
      allow_data_physic: {
        sameAs: sameAs(() => true)
      },
      allow_data_virtual: {
        sameAs: sameAs(() => true)
      },
    }
  },
  watch: {
    show_add_physical_card: function (newValue) {
      if (newValue == true) {
        this.show_add_virtual_card = false
        this.show_list_card = false
      }
    },
    show_add_virtual_card: function (newValue) {
      if (newValue == true) {
        this.show_add_physical_card = false
        this.show_list_card = false
      }
    },
    show_list_card: function (newValue) {
      if (newValue == true) {
        this.get_list_cards_user()
        this.show_add_virtual_card = false
        this.show_add_physical_card = false
      }
    },
  },
  methods: {

    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files;
      this.image_b64(uploadedFiles[0])
    },

    image_b64(file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.iconBase64 = reader.result
        this.uploaded_physic_card = this.iconBase64
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    handleFilesUploadVirtualScreanshot() {
      let uploadedFiles = this.$refs.filesScreanshot.files;
      this.image_b64VirtualScreanshot(uploadedFiles[0])
    },
    image_b64VirtualScreanshot(file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.iconBase64 = reader.result
        this.uploaded_virtual_card_screanshot = this.iconBase64
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    handleFilesUploadVirtualFact() {
      let uploadedFiles = this.$refs.filesFact.files;
      this.image_b64VirtualFact(uploadedFiles[0])
    },
    image_b64VirtualFact(file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.iconBase64 = reader.result
        this.uploaded_virtual_card_fact = this.iconBase64
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },

    send_physic_card() {
      this.$v.number_card_physic.$touch();
      if (this.$v.number_card_physic.$anyError) {
        return
      }
      this.$v.allow_data_physic.$touch();
      if (this.$v.allow_data_physic.$anyError) {
        return
      }
      this.$v.uploaded_physic_card.$touch();
      if (this.$v.uploaded_physic_card.$anyError) {
        return
      }
      let data = {
        'number_card': this.number_card_physic,
        'phisical_card': this.uploaded_physic_card,
        'allow_data': this.allow_data_physic,
      }
      axios.post(
        "/auth/verif_card_physic/",
        data
      ).then(() => {
        this.show_add_physical_card = false
        this.show_list_card = true
        this.number_card_physic = ''
        this.uploaded_physic_card= null
        this.allow_data_physic= false
        this.identitydocuments = null
        this.selfie = null
      }).catch(error => {
        console.error(error);
      });
      window.scrollTo(0, 0);
    },
    send_virtual_card() {
      this.$v.number_card_virtual.$touch();
      if (this.$v.number_card_virtual.$anyError) {
        return
      }
      this.$v.allow_data_virtual.$touch();
      if (this.$v.allow_data_virtual.$anyError) {
        return
      }
      this.$v.uploaded_virtual_card_screanshot.$touch();
      if (this.$v.uploaded_virtual_card_screanshot.$anyError) {
        return
      }
      this.$v.uploaded_virtual_card_fact.$touch();
      if (this.$v.uploaded_virtual_card_fact.$anyError) {
        return
      }
      let data = {
        'number_card': this.number_card_virtual,
        'virtual_card': this.uploaded_virtual_card_screanshot,
        'fact_virtual_card': this.uploaded_virtual_card_fact,
        'allow_data': this.allow_data_virtual,
      }
      axios.post(
        "/auth/verif_card_virtual/",
        data
      ).then(() => {
        // ЯКЩО УСПІШНО, то кидать вспливашку 200 і казати, що можна додати ще. Переглянути в LIST CARD - або просто відкривати LIST CARD
        // LIST CARD - завжди відкривати першим.
        this.show_add_virtual_card = false
        this.show_list_card = true
        this.number_card_virtual = ''
        this.uploaded_virtual_card_screanshot = null
        this.uploaded_virtual_card_fact = null
        this.allow_data_virtual = false
        
      }).catch(error => {
        console.error(error);
      });
    },
    get_list_cards_user() {
      axios.post(
        "/auth/list_cards_user/"
      ).then(data => {
        this.card_list = data.data
        // Принимаем:
        // type_card
        // number_card
        // status_card
      })
    },
    
  },
  mounted() {
    
  }
}
</script>

<style scoped>
.border_block_card {
  border: solid 1px;
  margin-top: 30px;
  border-radius: 5px;
  padding: 10px 20px;
}

.main_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.upload_files {
  width: 50%;
}

.card_title {
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}

.explain_upload_files {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.explain_upload_files img {
  width: 90%;
}

.input_card_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.main_list_card {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.title_list {
  font-weight: 700;
}

.group_btn_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.main_list_card li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  border-bottom: solid 1px #fff;
  padding: 10px 20px;
}
.main_list_card li span{
  width: 33%;
  text-align: left;
}

.group_btn_card .kyc_button {
  font-size: 16px !important;
}

.error_form {
  color: red;
}

.check_box input[type="checkbox"] {
  height: 24px;
  width: 24px;
  border: solid 1px #fff !important;
  background-color: inherit !important;
  cursor: pointer;
  margin: 0 20px 0px 0;
}

.check_box {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 25px;
  align-items: center;
}

.check_box input {
  width: 100%;
  height: 40px;
}

.check_box h4 {
  margin: 0;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}

.kyc_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

input[type=file] {
  width: 100%;
  height: 44px;
  font-size: 14px;
  max-width: 100%;
  color: #444;
  padding: 10px;
  background: #6e7ee7;
  border-radius: 10px;
  border: 1px solid #555;
}

#file-upload-button {
  font-size: 10px;
}

input[type=file]::file-selector-button {
  color: #000;
  font-size: 18px !important;


  font-size: large;
  font-weight: bold;
  background: white;
  border: 1px solid grey;
  border-radius: 5px;
  display: none;
}

input[type=file]::file-selector-button:hover {
  background: #485bd0;
  color: white;
  border-color: grey;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  border: 2px dashed white;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #404fb4;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #212b70;
}

.drop-title {
  color: #212b70;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

.title {
  text-align: center;
  margin-top: 4%;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  --c-action-primary: #2e44ff;
  --c-action-primary-accent: #e9e5ff;
  --c-action-secondary: #e5e5e5;
  --c-text-primary: #0d0f21;
  --c-text-secondary: #6a6b76;
  --c-background-primary: #d0d1de;
}

body {
  font-family: "Inter", sans-serif;
  color: var(--c-text-primary);
  background-color: var(--c-background-primary);
  line-height: 1.5;
}


select,
textarea {
  font: inherit;
}


.logo-circle svg {
  max-width: 1.5rem;
}


.upload-area-icon svg {
  max-height: 100%;
  max-width: 100%;
}


.upload-area-description strong {
  color: var(--c-action-primary);
  font-weight: 700;
}


.kyc-list-title {
  margin: 10px 0;
}

.needed-items-list {
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  margin: 10px 0;
}

.needed-items-list li {
  font-size: 16px;
  line-height: 28px;
}

.needed-items-list li a {
  color: #0e9cff;
  cursor: pointer;
  text-decoration: none;
}

.child {
  margin-left: 12px;
}


.kyc_button {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2a3587;
  background: #fff;
  border: none;
  width: 170px;
  padding: 10px;
  cursor: pointer;
  border: solid 1px #fff;
}

.kyc_button:hover {
  font-size: 20px;
  color: #fff;
  background: #2a3587;
}

.activeBtn {
  font-size: 20px;
  color: #fff;
  background: #2a3587;
}

.modal-kyc h4 {
  background-color: white;
  color: black;
  font-size: 18px;
  padding: 0;
}

.modal-kyc button {
  background-color: #3e4bb0;
  color: white;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 0;
  border: none;
}
@media (min-width: 1200px) and (max-width: 1440px) {}

@media (min-width: 992px) and (max-width: 1199px) {
  .needed-items-list li {
    font-size: 14px;
    line-height: 20px;
}
.group_btn_card .kyc_button {
  font-size: 14px !important;
}
.kyc_button {
  width: 140px;
}
.card_title {
  font-size: 14px;
  line-height: 18px;
}
.main_big p, .big_block p{
  font-size: 14px;
}
.main_big p, .big_block p{
  font-size: 14px;
}
.drop-container{
  padding: 10px;
  margin: 20px 0;
}
.check_box h4{
  font-size: 14px;
}
.main_list_card li{
  font-size: 15px;
}
}

@media (min-width: 768px) and (max-width: 991px) {
  .needed-items-list li {
    font-size: 14px;
    line-height: 20px;
}
.group_btn_card .kyc_button {
  font-size: 14px !important;
}
.kyc_button {
  width: 140px;
}
.card_title {
  font-size: 14px;
  line-height: 18px;
}
.main_big p, .big_block p{
  font-size: 14px;
}
.main_big p, .big_block p{
  font-size: 14px;
}
.drop-container{
  padding: 10px;
  margin: 20px 0;
}
.check_box h4{
  font-size: 14px;
}
.main_list_card li{
  font-size: 15px;
}
}

@media (min-width: 480px) and (max-width: 767px) {
  .needed-items-list li {
    font-size: 14px;
    line-height: 20px;
}
.group_btn_card .kyc_button {
  font-size: 14px !important;
}
.kyc_button {
  width: 140px;
}
.card_title {
  font-size: 14px;
  line-height: 18px;
}
.main_big p, .big_block p{
  font-size: 14px;
}
.main_big p, .big_block p{
  font-size: 14px;
}
.drop-container{
  padding: 10px;
  margin: 20px 0;
}
.check_box h4{
  font-size: 14px;
}
.main_list_card li{
  font-size: 15px;
}
}

@media (max-width: 479px) {
  .main_big input {
    width: 100%;
    font-size: 14px;
}
.main_card{
  flex-direction: column;
}
.upload_files, .explain_upload_files{
  width: 100%;
}
.explain_upload_files img{
  width: 100%;
  margin-top: 10px;
}
  .needed-items-list li {
    font-size: 14px;
    line-height: 20px;
}
.group_btn_card .kyc_button {
  font-size: 14px !important;
}
.kyc_button {
  width: 90px;
}
.card_title {
  font-size: 14px;
  line-height: 18px;
}
.main_big p, .big_block p{
  font-size: 14px;
}
.main_big p, .big_block p{
  font-size: 14px;
}
.drop-container{
  padding: 10px;
  margin: 10px 0 5px 0;
}
.main_list_card li{
  font-size: 11px;
}
.check_box {
  margin-top: 10px;
  margin-bottom: 5px;
}
.check_box input[type="checkbox"]{
  margin: 0 10px 0 0;
}
.check_box h4{
  font-size: 13px;
    line-height: 14px;
}
.child {
  margin: 0 0;
}
.main_list_card li{
  padding: 5px 0;
}
}
</style>