<template>
	<div class="main_dashboard">
		<div class="main_big">
			<div class="left_of_big">
				<KycDashboard />
				<CardDashboard/>
				<!-- <VerifKYC/> -->

			</div>
			<div class="right_of_big">
				<UserDashboard />
				<LogHistoryDashboard />
			</div>
		</div>
		<TwofactorDashboard class="big_block"/>
		
		<SuperAdmin v-if="isAdmin" />
		<StatisticAdmin v-if="isAdmin" />
		<TransactionDashboard />
		<ReferalsDashboard class="big_block"/>
		<!-- <WalletDashboard /> -->

		<!-- <FaqDashboard /> -->
		<!-- будет идея доработать тогда -->
		<!-- <PartnershipDashboard /> -->
	</div>
</template>

<script>
import axios from '../../axios';
import store from "@/store";
// import WalletDashboard from "./item_dashboard/wallet_dashboard.vue";
// import FaqDashboard from "./item_dashboard/faq_dashboard.vue";
import SuperAdmin from "./item_dashboard/super_admin.vue";
import StatisticAdmin from "./item_dashboard/statistic_admin.vue";
import ReferalsDashboard from "./item_dashboard/referals_dashboard.vue";
import TransactionDashboard from "./item_dashboard/transaction_dashboard.vue";
import UserDashboard from "./item_dashboard/user_dashboard.vue";
import LogHistoryDashboard from "./item_dashboard/log_history_dashboard.vue";
// import PartnershipDashboard from "./item_dashboard/partnership_dashboard.vue";
import TwofactorDashboard from "./item_dashboard/two_factor_avtorization.vue";
// import VerifKYC from "./item_dashboard/VerifKYC.vue";
import KycDashboard from "./item_dashboard/kyc_dashboard.vue";
import CardDashboard from "./item_dashboard/card_dashboard.vue";
// partnership
export default {
	components: {
		// WalletDashboard,
		// FaqDashboard,
		ReferalsDashboard,
		TransactionDashboard,
		TwofactorDashboard,
		UserDashboard,
		LogHistoryDashboard,
		KycDashboard,
		SuperAdmin,
		StatisticAdmin,
		// VerifKYC
		CardDashboard

		// PartnershipDashboard,
	},
	data() {
		return {
			admin: null
		}
	},
	mounted() {
		this.admin = localStorage.getItem('username')
		this.getRullers()
	},
	computed: {
		isAdmin: {
			get: () => store.getters.getIsAdmin,
			set: (payload) => store.commit("setIsAdmin", payload),
		}

	},
	methods: {
		getRullers() {
			try {
				axios.get('/auth/rulles/')
				.then((resp)=>{
					this.isAdmin = resp.data.is_admin
				})
				.catch(function (error) {
					console.error(error);
				});

			} catch (e) {
				alert('Error')
			}

		}
	}
};
</script>

<style>

.main_dashboard {
	width: 75%;
}

.main_big {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: #fff;
}
.big_block{
	color: #fff;
}
/* left_of_big
right_of_big */
.left_of_big {
	width: 70%;
	display: flex;
	flex-direction: column;
}

.right_of_big {
	width: 25%;
	display: flex;
	flex-direction: column;
}

.bgcbdr {
	background: rgba(255, 255, 255, 0.1);
	padding: 5%;
	margin-bottom: 50px;
}

@media (min-width: 1200px) and (max-width: 1440px) {}

@media (min-width: 992px) and (max-width: 1199px) {
	.left_of_big {
		width: 65%;
	}

	.right_of_big {
		width: 30%;
	}

	.main_big h3, .big_block h3 {
		font-size: 20px;
		line-height: 24px;
	}

	.main_big h4, .big_block h4 {
		font-size: 18px;
		line-height: 24px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.main_dashboard {
		width: 96%;
		padding: 0 2%;
	}

	.left_of_big {
		width: 65%;
	}

	.right_of_big {
		width: 30%;
	}

	.main_big h3, .big_block h3 {
		font-size: 18px;
		line-height: 24px;
	}

	.main_big h4, .big_block h4 {
		font-size: 16px;
		line-height: 20px;
	}
}

@media (min-width: 480px) and (max-width: 767px) {
	.main_dashboard {
		width: 96%;
		padding: 0 2%;
	}

	.main_big {
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
	}

	.right_of_big {
		width: 100%;
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	.user {
		width: 40%;
	}

	.log_history {
		width: 40%;
	}

	.left_of_big {
		width: 100%;
	}

	.bgcbdr {
		padding: 3%;
	}

	.main_big h3, .big_block h3 {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
	}

	.main_big h4, .big_block h4 {
		font-size: 14px;
		line-height: 18px;
	}
}

@media (max-width: 479px) {
	.main_dashboard {
		width: 96%;
		padding: 0 2%;
	}

	.main_big {
		width: 100%;
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;
	}

	.right_of_big {
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.user {
		width: 80%;
	}

	.log_history {
		width: 80%;
	}

	.left_of_big {
		width: 100%;
	}

	.bgcbdr {
		padding: 3%;
	}

	.main_big h3, .big_block h3 {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
	}

	.main_big h4, .big_block h4 {
		font-size: 14px;
		line-height: 18px;
	}

}
</style>
